<template>
    <div class="customImage_container global_container_center" ref="customImage">
        <img :src="src" :alt="src" :style="style" ref='image' @load="loadImage()"> 
    </div>
</template>
<script>
export default {
    name:"customImage",
    props:{
        src:String
    },
    data(){
        return {
            width:0,
            height:0,
            win:{
                width:0,
                height:0
            },
            style:""
        }
    },
    methods:{
        laodStyle(){
           let height,width;
            // if(this.height < this.win.height && this.width<this.win.width){
            //     height = this.height;
            //     width = this.width;
            // }else{
                let heightScale = this.win.height/(this.height || 1);
                let widthScale = this.win.width/(this.width || 1);
                height = this.win.height
                width = this.width*heightScale
                if(width > this.win.width){
                    width = this.win.width
                    height = this.height*widthScale
                }
            // }
            this.style = `width:${width}px;height:${height}px`
        },
        loadImage(){
            let image = new Image()
            image.src = this.src
            image.onload = (res) => {
                this.width = image.width
                this.height = image.height
                this.win.width = this.$refs.customImage.clientWidth
                this.win.height = this.$refs.customImage.clientHeight
                this.laodStyle()
                console.log("获取图片尺寸",this.width,this.height,this.win)
            } 
        }
    },
}
</script>
<style>
.customImage_container{
    width:100%;
    height:100%;
    background:#434343;
    overflow: hidden;
}
.customImage_container img{
    width:0;
    height:0;
}
</style>

